'use client';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { useState } from 'react';

function Accordion(props: {
  title: string,
  titleClassName?: string,
  fullWidth?: boolean,
  buttonClassName?: string,
  children?: React.ReactNode,
  borderColor?: string,
  textColor?: string
}) {
  const [open, setOpen] = useState(false);

  let borderColor = '';
  if (props.borderColor) {
    borderColor = props.borderColor;
  }
  else {
    borderColor = 'border-cgray-300';
  }

  let textColor = '';
  if (props.textColor) {
    textColor = props.textColor;
  }
  else {
    textColor = 'text-cgray-300';
  }




  return <div
    className={`
      border rounded relative ${borderColor} 
      ${props.fullWidth ? 'w-full' : ''}
      ${props.buttonClassName ? props.buttonClassName : ''}
      
    `}
  >
    <div
      className='flex justify-between items-center py-2 px-4 w-full gap-4 cursor-pointer'
      onClick={() => setOpen(!open)}
    >
      <div className={`text-center text-xl font-medium mx-auto ${textColor} 
        ${props.titleClassName ? props.titleClassName : ''}
      `}>
        {props.title}
      </div>
      <div className={`
        text-gray-500 border ${borderColor} rounded-full w-6 h-6
        flex items-center justify-center
        cursor-pointer 
        ${props.buttonClassName}
      `}
      >
        {open
          ? <ChevronUpIcon className={`w-6 h-6 ${textColor}`} />
          : <ChevronDownIcon className={`w-6 h-6 ${textColor}`} />}
      </div>
    </div>
    {open && props.children && <div className='px-4 pb-4'>
      {props.children}
    </div>}
  </div>;
}

export default Accordion;
