function Section(props: {
  id?: string,
  className?: string,
  children: React.ReactNode
}) {
  return <section
    id={props.id}
    className={`p-8 space-y-6 ${props.className}`}
  >
    {props.children}
  </section>;
}

export default Section;
