import React from 'react'

function StreamPreview(props: {
  streamUrl: string,
}) {
  return <div className='bg-black aspect-video w-full rounded-lg mx-auto'>
    <iframe
      className='w-full h-full rounded-lg'
      src={props.streamUrl}
      frameBorder='0'
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  </div>;
}

export default StreamPreview;
