'use client';

//import { formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';


import { useCallback, useState } from 'react';
import Accordion from '../../components/ui/Accordion';
import Button from '../../components/ui/Button';
import Heading from '../../components/ui/Heading';
import Paragraph from '../../components/ui/Paragraph';
import Section from '../../components/ui/Section';


import GetKismetNumberDetailText from '../ui/GetKismetNumberDetailText';

function EnterPhoneNumber(props: {
  hideHeading?: boolean;
  onChange?: (phoneNumber: string) => void;
}) {

  const [legalAgreement, setLegalAgreement] = useState(false);


  const [countryCode, setCountryCode] = useState('1');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [error, setError] = useState('');
  const [errorPhoneNumberInput, setErrorPhoneNumberInput] = useState('');
  
  
  const handleSignUp = async () => {

    window.location.href = 'https://kismetnumbers.com/signup';
  }

  const handleAgreementOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    // Reset any previous error
    setError('');

    setLegalAgreement(e.target.checked)
  }

  return <Section
    id='get-your-free-kismet-number'
    className={'p-8 space-y-6 bg-kbeige flex flex-col items-center pb-[200px]'}
  >
    {!props.hideHeading && <>
      <Heading className='text-center'>
        Get Your Free Kismet Number
      </Heading>
      <Paragraph className='text-xl text-center'>
        No sensitive information required (not even your last name)
      </Paragraph>
      <Accordion borderColor='border-cbronze-300' textColor='text-cblue-400' title='See More Details' fullWidth>
        <GetKismetNumberDetailText className='text-gray-500'/>
      </Accordion>
      <hr className='w-full border-gray-300' />
    </>}
    <div className='flex flex-col w-full items-center gap-3'>
    <Heading className='flex gap-3 items-center'>
      <img
          src='/assets/mpa/Clock.png'
          alt='Clock'
          className='w-8 h-8'
          height={32}
          width={32}
        />
        1 Minute Setup
      </Heading>
      
        <Button
        className='rounded-lg w-full h-[130px] text-center border border-blue-800'
          variant='PRIMARY'
          onClick={handleSignUp}
        >
          GET MY FREE KISMET NUMBER
        </Button>

    </div>
  </Section>;
}

export default EnterPhoneNumber;
