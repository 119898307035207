function Paragraph(props: {
  children: React.ReactNode,
  className?: string
  
}) {
  let thisClassName = '';

  if (props.className) {
    if (props.className.indexOf ('text-cbronze-300') === -1) {
      thisClassName = props.className + ' text-cgray-300';
    }
    else {
      thisClassName = props.className;
    }

  }
  else {
    thisClassName = 'text-cgray-300';
  }

  return (<p className={'text-sm font-sans leading-relaxed ' + thisClassName}>{props.children}</p>);
}

export default Paragraph;
