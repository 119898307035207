
function Heading(props: {
  children: React.ReactNode,
  className?: string
}) {
  return <h1 className={`
    text-3xl text-cblue-500 font-mono
    leading-relaxed
    ${props.className}
  `}>
    {props.children}
  </h1>;
}

export default Heading;
