import React, { useEffect, useState } from 'react'; // Import React for JSX
import { useParams } from 'react-router-dom';
import { isValidReferralID } from './utility/referral';

import Cookies from 'universal-cookie';


import StreamPreview from './components/StreamPreview';
import Accordion from './components/ui/Accordion';
import Button from './components/ui/Button';
import Heading from './components/ui/Heading';
import Paragraph from './components/ui/Paragraph';
import Section from './components/ui/Section';
import HowItWorksText from './components/ui/HowItWorksText';
import AgeRestrctionBanner from './components/AgeRestrictionBanner';
import OurPromise from './components/ui/OurPromise';
import EnterPhoneNumber from './components/signupUser/EnterPhoneNumber';
import { dqueryAppSetting, dquery } from './service/dquery';
import Header from './components/header';
//APP IS DEPLOYED USING REACT, NOT NEXTJS
function ReferralPage(): JSX.Element {

const cookies = new Cookies();
const { referralID } = useParams();

const [appSetting, setAppSetting] = useState({homeIntroDescription: '', homeIntroStreamUrl: '', homeWinnerDescription: '', homeWinnerStreamUrl: ''});


  useEffect(() => {

    async function fetchAppSetting() {
      try {
        const appSetting:any = await dqueryAppSetting ();
  
        if (appSetting)
          setAppSetting(appSetting);
        //console.log('appSetting: ' + JSON.stringify(appSetting));
  
      } catch (error) {
        console.error('Error fetching app setting:', error);
      }
    }
  
  
    fetchAppSetting();

    queryShow ();

  }
  , []);



useEffect(() => {

// Validate referral ID (as in previous responses)  

    if (referralID) {
        // Validate referral ID (as in previous responses)
        if (isValidReferralID(referralID)) {
            // Set cookie
            //console.log ('referralID: saved: ' + referralID)

            cookies.set('referralID', referralID, { path: '/', maxAge: 31536000, domain: '.kismetnumbers.com' }); //1 years
        }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [referralID]);

async function queryShow () {
  try {
    const showData:any = await dquery('shows');

    //this stageStatus is provided by the backend dquery shows api
    //Latest Show OR Next Show

    if (!showData)
    {
      return;
    }

/* 
    if (showData.showsUpcoming)
    {
      setShowUpcoming(JSON.parse(showData.showsUpcoming));    
    }

    //console.log ('showUpcoming: ' + JSON.stringify(showUpcoming));  

    showData.shows.map ((show:any) => { 

      if (show.stageStatus === 'Latest Show') {
        setShowLatest(show);

        //console.log('showLatest: ' + JSON.stringify(show));
      }
      else if (show.stageStatus === 'Next Show') {
        setShowNext(show);
      }
    }); */
      
    //console.log('showData: ' + JSON.stringify(showData));

  } catch (error) {
    console.error('Error fetching showData:', error);
  }
}



function Hero() {
  const htmlString = `<div style="color:#7B7B7B; font-weight: normal; text-align: center">We are <span style="color:#779bea; font-weight: bold;">not</span> a lottery - we're <span style="color:#779bea; font-weight: bold;">better</span><br /><br />It's <span style="color:#779bea; font-weight: bold;">free</span> to join - we will <span style="color:#779bea; font-weight: bold;">never</span> ask you for money - and we will <span style="color:#779bea; font-weight: bold;">never</span> ask you to buy <span style="color:#779bea; font-weight: bold;">anything</span> to be eligible to win.<br /><br />Watch for <span style="color:#779bea; font-weight: bold;">2 minutes</span> to learn more</div>`; //{appSetting.homeIntroDescription}
  
  return <Section id='hero' className='p-8 space-y-6 bg-kbeige'>
    
    <Heading className='text-center'>
      What is Kismet Numbers?
    </Heading>
    <div dangerouslySetInnerHTML={{ __html: appSetting.homeIntroDescription }} />

    <div className='flex flex-col items-center flex-1 gap-6'>
      <StreamPreview
        streamUrl={appSetting.homeIntroStreamUrl}
      />
      <Accordion textColor='text-[#6E9AE3]' title='Our Promise To You' fullWidth>
        <OurPromise/>
      </Accordion>
    </div>

  </Section>
}

function HowItWorks() {
  const items = [
    {
      title: 'Join <span style="color: white">free</span> and pick your Kismet Number to <span style="color: white">automatically</span> be entered to win <span style="color: white">every prize</span> on <span style="color: white">every Show</span> we do <span style="color: white">forever</span>',
      //content: 'no sensitive info required'
    },
    {
      title: 'On <span style="color: white">every</span> Show we randomly pick a <span style="color: white">Guaranteed Winner</span> from our site for a <span style="color: white">$1000 - $5000</span> "Spin Guarantee Win" prize',
      //content: 'Guaranteed winners per show'
    },
    {
      title: 'On top of that, we <span style="color: white">"Let It Rain Money"</span> on <span style="color: white">every</span> Show to <span style="color: white">10 more</span> random Guaranteed Winners from our site.',
      //content: 'it\'s that simple'
    },
    {
      title: '<span style="color: white">Simply watch</span> each Show on YouTube to see if your <span style="color: white">Kismet Number</span> is picked as a <span style="color: white">Winner.</span>',
      //content: 'it\'s that simple'
    },
    {
      title: `<span style="color: white">If you win,</span> just log into our site within <span style="color: white">24 hours</span> to claim your prize - then we pay you. It's <span style="color: white">that simple.</span>`,
      //content: 'it\'s that simple'
    }
  ]

/*    <h2 className='text-cyellow-500 text-xl'>
              {item.title}
            </h2>
            <p className='text-white'>
              {item.content}
            </p>*/

  return <Section id='how-it-works' className='bg-kblue'>
    <div className='flex flex-row items-center justify-center gap-6'>
      <Heading className='text-white'>
        How It Works
      </Heading>
      <img
        src='/assets/home/How-It-Works-Star.png'
        alt='How It Works Star'
        height={60}
        width={60}
        className='w-[60px] h-[60px]'
      />
    </div>
    <ul className='flex flex-col gap-4'>
      {items.map((item, index) => {
        return <li className='relative flex items-center my-3' key={index}>
          <div className='font-mono text-[4rem] mr-6 min-w-9 text-center'>
            <span className='text-blue-300'>
              {index + 1}
            </span>
          </div>
          <div className='text-cyellow-500 text-xl'>
           <div dangerouslySetInnerHTML={{ __html: item.title }} />
          </div>
          
        </li>
      })}
    </ul>
    <div>
      <Accordion
        title='Learn More Details'
        buttonClassName='border-yellow-200'
        titleClassName='text-white'
        textColor='text-cyellow-500'
        borderColor='text-cyellow-500'

      >
        <HowItWorksText className='!text-[#D7D7D7]'/>
      </Accordion>
    </div>
  </Section>
}

function RecentWinners() {
  

  return <Section id='recent-winners' className={`bg-kblue`}>
    <div className='flex flex-col items-center space-y-4'>
      <Heading className={'!text-white'}>
        Recent Winners
      </Heading>
      <Paragraph className={`text-xl !text-[#FBDD77]`}>
        {appSetting.homeWinnerDescription}
      </Paragraph>
      <StreamPreview
        streamUrl={appSetting.homeWinnerStreamUrl}
      />
      <a href='/recent-winners'>
        <Button variant={"YELLOW"} className='bg-cyellow-300 !text-kblue'>
          See All Our Recent Winners
        </Button>
      </a>
    </div>
  </Section>
}


return <main className='flex flex-col w-full'>
  
  <Header />
  <div className='min-h-28 block w-full'></div>
  <AgeRestrctionBanner />
  
  <Hero />
  <HowItWorks />
  <EnterPhoneNumber />
</main>;
}

export default ReferralPage;
