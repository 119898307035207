import Paragraph from "./Paragraph";


function OurPromise() {
  

  return (<div className='text-xl text-gray-500'>
  <ul className='pl-7 list-disc '>
    <li>We will never ask you for money or try to sell you anything.</li>
    <li>Your kismet number is exclusively yours and yours alone.</li>
    <li>You are guaranteed to be part of every Kismet Numbers draw.</li>
    <li>There are multiple winners, every draw.</li>
    <li>We will not sell, lease, or transfer your data to any third party.</li>
    <li>We aspire to be the world&apos;s first and best Social Giving Platform.</li>
    <li>Our mission is to keep on giving.</li>

  </ul>
  <div className='w-full flex items-right justify-end'>
    <div className='mt-10 border-gray-600 w-[250px]'>
      <ul className='pl-7 list-none'>
        <li>Mr. J.P. Kenny</li>
        <li>C.E.O/Cofounder</li>
        <li>Kismet Numbers Corp.</li>
      </ul>
    </div>
  </div>
  </div>);
}

export default OurPromise;
