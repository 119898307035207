'use client';

import { useState } from 'react';
import { CircleLoader } from 'react-spinners';


function Button(props: {
  type?: 'button' | 'submit' | 'reset',
  variant: 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'YELLOW',
  children: React.ReactNode,
  leadingChildren?: React.ReactNode,
  className?: string,
  disabled?: boolean,
  onClick?: () => Promise<void>
}) {
  const [isLoading, setIsLoading] = useState(false);
  const primaryClasses = `
    bg-button-blue-400 text-white ${!props.disabled && 'hover:bg-blue-500'} ${!props.disabled && !isLoading && 'active:bg-cyan-500'}
  `;
  const secondaryClasses = `
    text-red-950 bg-kbeige ${!props.disabled && 'hover:bg-red-950 hover:text-white'} ${!props.disabled && !isLoading && 'active:bg-cyan-500'}
  `;
  const yellowClasses = `
    text-kblue bg-yellow-400 ${!props.disabled && 'hover:bg-yellow-500'} ${!props.disabled && !isLoading && 'active:bg-cyan-500'}
  `;

  const tertiaryClasses = `
    text-[#3763AC] bg-kbeige border border-[#a99b89] ${!props.disabled && 'hover:text-blue-700'} ${!props.disabled && !isLoading && 'active:bg-blue-300'}
  `;

  async function handleOnClick() {
    if (props.onClick) {
      setIsLoading(true);
      await props.onClick();
      setIsLoading(false);
    }
  }

  const variantClasses = props.variant === 'PRIMARY'
    ? primaryClasses
    : props.variant === 'SECONDARY'
      ? secondaryClasses
      : props.variant === 'TERTIARY'
        ? tertiaryClasses
        : yellowClasses;


    
  return <button
    type={props.type}
    className={`
    flex items-center justify-center text-nowrap px-4 py-2 rounded font-medium ${variantClasses} text-xl
      ${props.disabled || isLoading ? 'opacity-50 cursor-not-allowed' : ''}
      ${props.className || ''}
    `}
    disabled={props.disabled || isLoading}
    onClick={() => {
      void handleOnClick();
    }}
  >
    {props.leadingChildren}
    {isLoading ? <div className='flex flex-row items-center justify-center text-center'><CircleLoader color={props.variant === 'PRIMARY' ? '#fff': '#000' } loading={isLoading} size={20}/>&nbsp;Loading ...</div> : props.children}
  </button>;
}

export default Button;
