'use client';

import { ChevronDownIcon } from '@heroicons/react/16/solid';

import { useState, useEffect, useRef } from 'react';



function LinkGroup(props: {
  title: string,
  href?: string,
  links?: {
    title: string,
    href: string,
  }[],
  onLinkClick?: () => void,
  onLinkBlur?: () => void,
}) {
  const [open, setOpen] = useState(false);



  const handleClick = (passOnClick: boolean = true) => {
    setOpen(!open);

    if (passOnClick) {
      // if (!props.links || props.links.length === 0) {
      props.onLinkClick && props.onLinkClick();
      // }
    }
  }

  const handleBlur = () => {
    // if (!props.links || props.links.length === 0) {
    props.onLinkBlur && props.onLinkBlur();
    // }
  }

  return <div className='py-2 text-2xl'>
    {!props.href && <a
      className='font-normal text-white cursor-pointer select-none'
      // onClick={handleClick}
      // onBlur={handleBlur}
      onClick={() => handleClick(false)}
    >
      {props.title}
      {props.links && props.links.length > 0 && <ChevronDownIcon
        className={`
          cursor-pointer
          bg-neutral-50 rounded-full text-gray-500
          inline-block w-5 h-5 ml-2
          ${open ? 'transform rotate-180' : ''}
        `}
      />}
    </a>}
    {props.href && <a href={props.href}
      className='font-normal text-white hover:text-white'
      onClick={() => handleClick(true)}
      onBlur={handleBlur}
    >
      {props.title}
    </a>}
    {props.links && open && <div className='flex flex-col pl-2 mt-2'>
      {props.links.map((link, index) => <a
        onClick={() => handleClick(true)}
        onBlur={handleBlur}
        key={index}
        href={link.href}
        className='text-[#cdd6d5] py-2 hover:text-white'
      >
        - {link.title}
      </a>)}
    </div>}
  </div>
}

function Header() {

  const [dropDownOpen, setDropDownOpen] = useState(false);


  
  const headerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  ////console.log('HEADER USER', JSON.stringify(USER));
  const portalText = 'Your Member Portal';


  return  <header className='fixed top-0 z-50 bg-kblue w-full max-w-[1380px]'>
  <div ref={headerRef} className='w-full h-28 flex items-center justify-between py-1 px-4 border-b border-[#6e9ae3]'>
      <a href='/'>
        <img
          src='/assets/mpa/Kismet-Logo.svg'
          alt='Kismet Logo'
          height={112}
          width={240}
          className='h-28 w-60'
          onClick={() => setDropDownOpen(false)}
        />
      </a>




      <div
        onClick={() => setDropDownOpen(!dropDownOpen)}
        className='cursor-pointer h-8 w-8'
      >
        {dropDownOpen
          ? <img
            src='/assets/mpa/Nav-Menu-Close.png'
            alt='Close Menu'
            loading='eager'
            className='h-8 w-8'
            height={32}
            width={32}
          />
          : <img
            src='/assets/mpa/Nav-Menu-Bars.png'
            alt='Open Menu'
            loading='eager'
            className='h-8 w-8'
            height={32}
            width={32}
          />}
      </div>
    </div>
    {dropDownOpen && <div className='flex bg-[#818b8a] flex-col items-end '>
      <div ref={menuRef} className='px-6 py-4 space-y-2 text-xl'>
        <div>
          <LinkGroup
            title='Get Your Kismet Number'
            href='https://kismetnumbers.com/signup'
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
          />
        </div>
        <div>
          <LinkGroup
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
            title='How It Works'
            links={[
              {
                title: 'Overview',
                href: 'https://kismetnumbers.com/how-it-works/overview',
              },
              {
                title: 'Get Bonus Numbers',
                href: 'https://kismetnumbers.com/how-it-works/get-bonus-numbers',
              }
            ]}
          />
        </div>{/* 
        <div>
          <LinkGroup
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
            title='Our Show'
            links={[
              {
                title: 'Latest Show',
                href: 'https://kismetnumbers.com/our-shows/latest-show',
              },
              {
                title: 'Upcoming shows',
                href: 'https://kismetnumbers.com/our-shows/upcoming-shows',
              },
              {
                title: 'Previous Shows',
                href: 'https://kismetnumbers.com/our-shows/previous-shows',
              }
            ]}
          />
        </div>
        <div>
          <LinkGroup
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
            title='Recent Winners'
            href='https://kismetnumbers.com/recent-winners'
          />
        </div> */}
        <div>
          <LinkGroup
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
            title='FAQs'
            href='https://kismetnumbers.com/faqs'
          />
        </div>
        <div>
          <LinkGroup
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
            title='About Us'
            links={[
              {
                title: 'Our Mission',
                href: 'https://kismetnumbers.com/about-us/our-mission',
              },
              {
                title: 'Our Vision',
                href: 'https://kismetnumbers.com/about-us/our-vision',
              },
              {
                title: 'Our Promise',
                href: 'https://kismetnumbers.com/about-us/our-promise',
              }
            ]}
          />
        </div>
        <div>
          <LinkGroup
            onLinkBlur={() => setDropDownOpen(false)}
            onLinkClick={() => setDropDownOpen(false)}
            title='Contact Us'
            href='/contact-us'
          />
        </div>
        
      </div>
    </div>}



  </header>
}

export default Header;