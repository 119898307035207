import Paragraph from "./Paragraph";


function HowItWorksText(props: {className?: string}) {
  let thisClassName = '';
  if (props.className) {
    thisClassName = props.className;
  }

  return (<div className={'flex flex-col gap-4 text-cgray-300 ' + thisClassName}>
            
    <div className='text-2xl mb-6 text-center'>Empowering Change Through Social Engagement</div> 
    <div className='text-xl'>Introduction</div>
    <div className='text-lg mb-2'>Our platform is dedicated to raising awareness about pressing global issues and supporting charities and nonprofits in their missions. We&apos;ve developed an innovative approach that leverages the power of social media to
  generate funds for these organizations. Our aim is to create a sustainable source of income for charities and nonprofits while engaging users in meaningful ways.
    </div>

    <div className='text-xl'>Leveraging Social Media for Impact</div>
    <div className='text-lg mb-2'>Traditional fundraising often relies on direct donations, but we take a different approach. By reverse engineering
    the revenue generated by social media platforms through advertising and partnerships, we redirect revenue
    generated funds through unique views to support charities and non-profits. This means that every time you engage
    with our platform, you&apos;re directly contributing to your charity of choice and their important causes.</div>

    <div className='text-xl'>Empowering Users to Make a Difference</div>
    <div className='text-lg mb-2'>When you join our platform, you become part of a community dedicated to positive change. Your engagement
    directly translates into financial support for your charities or non-profits. This model empowers users to make a
    tangible difference without making monetary donations.</div>

    <div className='text-xl'>Weekly Cash Giveaway Show: Our Value Proposition</div>
    <div className='text-lg mb-2'>Kismet Numbers hosts a live 10-minute show every week to give away money and prizes to lucky winners. It&apos;s
    completely free to participate, and there&apos;s always a winner because the winner is drawn from the pool of
    subscribers.</div>

    <div className='text-xl'>Engagement Is Key</div>
    <div className='text-lg mb-2'>The weekly cash giveaway show is more than just a fun event—it&apos;s the heart of our value proposition. Your
    participation creates views, and views generate revenue for your charity of choice or your nonprofit corporation.
    Therefore, active engagement is of utmost importance. The more you engage, the more revenue is generated for
    the cash giveaways and in turn the causes you care about.</div>

    <div className='text-xl'>Amplifying Awareness for Global Issues</div>
    <div className='text-lg mb-2'>Beyond the thrill of winning cash prizes, these giveaways serve a larger purpose. They help to shine a spotlight on
    the critical global issues our partner charities and non-profits are addressing. The more people participate, the
    more attention we can bring to these important causes, ultimately driving greater awareness and support.</div>

    <div className='text-xl'>Creating Sustainable Impact</div>
    <div className='text-lg mb-2'>By participating in our platform, you&apos;re not just supporting charities and non-profits financially; you&apos;re also
    helping to create a sustainable source of income for them. This steady funding allows charities and non-profits to
    focus on their missions without the constant stress of fundraising. Together, we&apos;re making a lasting impact on the
    world.</div>


    <div className='text-xl'>Join Us in Making a Difference</div>
    <div className='text-lg mb-2'>Ready to join our community and be a part of something bigger? Sign up for our platform today to start making a
    difference. Whether you&apos;re passionate about environmental conservation, education, or social justice, there&apos;s a
    cause for you to support. Together, we can drive positive change and build a brighter future for all.</div>

  </div>);
}

export default HowItWorksText;
