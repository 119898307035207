
import './App.css';
import './tailwind.css'; // Adjust the path as needed
import { BrowserRouter, Routes, Route, Link, useParams } from 'react-router-dom';
import ReferralPage from './ReferralPage';
import { useEffect } from 'react';



//APP IS DEPLOYED USING REACT, NOT NEXTJS

function App() {

  useEffect(() => {
    document.title = "Kismet Numbers";
  }, [])

  return (<BrowserRouter>
      <Routes>
        <Route path="/" element={<ReferralPage />} />  {/* Dynamic route */}
        <Route path="/:referralID" element={<ReferralPage />} />  {/* Dynamic route */}
      </Routes>
    </BrowserRouter>
    );
}
export default App;
