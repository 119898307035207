


function GetKismetNumberDetailText(props: {className?: string}) {
  let thisClassName = '';
  if (props.className) {
    thisClassName = props.className;
  }

  return (<div className={thisClassName}>
    <div className='text-lg mb-4'>
      Selecting your Kismet number couldn&apos;t be easier - it&apos;s as easy as 1-2-3! And rest assured, we prioritize your privacy. We never utilize or trade your personal details; check out our &quot;Promise to You&quot; section for more details. Here&apos;s how it works:
    </div>
    <div className='flex flex-col gap-2 mb-4'>
      <div className='text-lg'>
        1. Enter your mobile number - that&apos;s your ticket to the world of Kismet.
      </div>
      <div className='text-lg'>
        2. Receive and insert your personal SMS verification code - just to ensure everything&apos;s secure.
      </div>
      <div className='text-lg'>
        3. Enter your first name, password and email to create your own Kismet profile. 
      </div>
      <div className='text-lg'>
        4. Now comes the fun part - pick your very own Kismet number!
      </div>
    </div>
    <div className='text-lg'>
      It&apos;s quick, it&apos;s simple, and it&apos;s secure. In less than a minute, you&apos;re on your way to a lifetime of winning opportunities. Feel confident and comfortable with our user-friendly process, knowing your privacy is our priority.
    </div>
  </div>);
}

export default GetKismetNumberDetailText;



