
import { useEffect, useState } from 'react';

import Heading from '../components/ui/Heading';
function AgeRestrctionBanner() {

  const [showAgeRestriction, setShowAgeRestriction] = useState(false);
  
  
  useEffect(() => {
    const ageRestriction = sessionStorage.getItem('ageRestriction');
    if (ageRestriction === 'true') {
      setShowAgeRestriction (false);
    }
    else
    {
      setShowAgeRestriction (true);
    }
  }, []);

  
  async function handleConfirmation (confirmation:boolean) {
    if (confirmation) {
    
      setShowAgeRestriction (false);
      sessionStorage.setItem('ageRestriction', 'true');
    }
    else
    {
      window.location.href = 'https://www.google.com';
    }
  }
  
  return (
  <>

  {showAgeRestriction && (
      <div className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50">
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 border border-current rounded-sm bg-white inset-0 flex justify-center items-center w-[400px] h-[200px]">
        <div className="flex flex-col justify-start items-center bg-white rounded-md mx-1 min-w-[396px] h-[400px] border border-gray-500 pt-6">
        
        <img
          src='/assets/mpa/Kismet-Logo-0.5x.png'
          alt='Kismet Logo'
          height={112}
          width={240}
          className='h-28 w-60'
        />
        <div className='text-2xl text-cblue-400 text-center my-4'>AGE VERIFICATION</div>
        <div className='text-lg text-center my-4'>KismetNumbers.com can only be used by adults 18+ years of age:</div>

          <div className="flex flex-row gap-6 justify-between items-center my-4">
            <button type='button' onClick={() => handleConfirmation(false)} className="bg-[#a89b89] text-white font-medium px-6 py-4 rounded-md">I&apos;m Not 18</button>
            or
            <button type='button' onClick={() => handleConfirmation(true)} className="bg-[#5177b8] text-white font-medium px-6 py-4 rounded-md mr-2">I Am 18+</button>
          </div>
        </div>
      </div>
    </div>
      )}
  </>


  );
}

export default AgeRestrctionBanner;

